/** @jsx jsx */
import Image from 'components/image';
import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-bootstrap';
import { Box, Container, Heading, Text, jsx } from 'theme-ui';


const PunchlistDetail = () => {
    const data = useStaticQuery(graphql`
    query {
      ImagePunchlist: file(relativePath: { eq: "punchlist.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG])
        }
      }
      ImagePunchlistDesign: file(relativePath: {eq: "punchlist-design.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG], quality: 100)
        }
      }
      ImagePunchlistApp: file(relativePath: {eq: "punchlistapp.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG], quality: 100)
        }
      }
      ImagecbcIcon: file(relativePath: {eq: "cbc-icon.png"}) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, PNG], quality: 100)
        }
      }
    }
  `);
    return (
        <Box as="section" id="Blog_Details" sx={styles.section}>
            <Container>
                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImagePunchlist.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="cbc"
                        className="rounded"
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto mb-5">
                    <Heading sx={styles.title}>
                        About Punchlist
                    </Heading>
                    <Text as="p" sx={styles.summary}>
                        We collaborated with construction professionals to develop Punchlist, a tool that simplifies the management of building tasks. Punchlist brings all your construction tasks into one easy-to-use platform, making it faster and more efficient to get them done.
                    </Text>
                    <Heading sx={styles.title}>
                        The Challenge
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Managing construction tasks can be complex and time-consuming. With Punchlist, we aimed to streamline this process and provide a solution that helps construction teams stay organized and focused on completing their projects.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our goal was to create a platform that integrates seamlessly with existing project management tools, allowing construction professionals to easily create, assign, and track tasks. We wanted to eliminate the need for scattered lists and inefficient communication methods, providing a centralized hub for all construction-related tasks.
                    </Text>
                    <Heading sx={styles.title}>
                        The Process
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        To develop Punchlist, we started by identifying the goals and priorities of construction professionals. We conducted interviews and discussions to understand their pain points and ideal features for a task management tool.
                    </Text>
                    <Heading sx={styles.title}>
                        Designing For Construction Professionals
                    </Heading>
                    <Heading sx={styles.title}>
                        General Contractors
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-transparent">Experienced professionals in the construction industry</li>
                            <li class="list-group-item bg-transparent">Manage multiple projects simultaneously</li>
                            <li class="list-group-item bg-transparent">Require a comprehensive task management tool to keep projects on track</li>
                        </ul>
                    </Text>
                    <Heading sx={styles.title}>
                        Subcontractors
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-transparent">Specialized professionals hired by general contractors</li>
                            <li class="list-group-item bg-transparent">Focus on specific tasks within a construction project</li>
                            <li class="list-group-item bg-transparent">Need clear instructions and deadlines for their tasks</li>
                        </ul>
                    </Text>
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto pb-5">
                    <Heading sx={styles.title}>
                        Design Process
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our design process for Punchlist began with creating wireframes to visualize the app experience. These wireframes helped us validate our ideas and ensure they aligned with the needs of construction professionals.
                    </Text>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our goal was to create a user-friendly interface that simplifies task management for construction teams. We focused on providing a seamless experience that allows users to easily create, assign, and track tasks.
                    </Text>
                </Col>
                <Col xl="9" lg="9" md="10" className='mx-auto'>
                    <Image
                        src={data.ImagePunchlistDesign.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="Webhelp formation"
                        className=""
                    />
                </Col>
                <Col xl="7" lg="7" md="10" className="mx-auto py-5">
                    <Heading sx={styles.title}>
                        UX | UI Design
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Our goal with Punchlist was to create a user-friendly interface that simplifies the management of building tasks. We focused on providing a seamless experience that allows construction professionals to easily create, assign, and track tasks.
                    </Text>
                    <Image
                        src={data.ImagePunchlistApp.childImageSharp.gatsbyImageData}
                        loading="lazy"
                        alt="Webhelp formation"
                        className=""
                        sx={{
                            width: ['100%', '350px'], // Set width to 100% for mobile and 250px for desktop
                            margin: '0 auto', // Center the image
                        }}
                    />
                    <Heading sx={styles.title}>
                        Keyboard Integration
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        We understood the importance of having an intuitive task creation and management system, akin to a digital keyboard. Punchlist offers a seamless integration that allows users to quickly access and manage tasks, much like using an emoji keyboard.
                    </Text>
                    <Heading sx={styles.title}>
                        Cross-Platform Compatibility
                    </Heading>
                    <Text as="p" sx={styles.summary} className='mb-4'>
                        Punchlist was designed to be compatible with both Android and iOS devices, ensuring that construction professionals can easily access and manage their tasks on any device. Our design process focused on creating a consistent experience across different platforms.
                    </Text>
                </Col>
            </Container>
        </Box >
    );
};

export default PunchlistDetail;

const styles = {
    section: {
        backgroundColor: '#f3f6fe',
        pt: [8, null, 12, 6, null, 10],
        pb: [null, null, null, 8, 0],
    },
    grid: {
        gap: [null, null, null, null, 12],
        display: ['flex', null, null, 'grid'],
        flexDirection: ['column-reverse', null, null, 'unset'],
        alignItems: 'center',
        gridTemplateColumns: ['1fr', null, null, null, '470px 1fr', '1fr 549px'],
    },
    illustration: {
        textAlign: 'center',
        position: 'relative',
        mt: [2, null, null, 0, 4, 0],
        img: {
            maxWidth: ['100%', null, null, null, null, ''],
        },
    },
    content: {
        marginTop: [null, null, null, null, null, -16],
        maxWidth: [null, null, null, 420, 560, 'none'],
        margin: [null, null, null, '0 auto', 'unset'],
        textAlign: ['center', null, null, null, 'left'],
        mb: 4
    },
    titlePhase: {
        color: '#ec1c23',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    title: {
        color: '#09386b',
        fontFamily: 'headingAlt',
        fontSize: [4, null, null, 8, null, null, 14],
        fontWeight: 500,
        lineHeight: [1.33, null, 1.4, 1.2],
        letterSpacing: ['-0.5px', null, null, '-1px'],
        mb: 3,
        mt: 6,
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
    },
    summary: {
        color: 'textSecondary',
        fontFamily: 'headingAlt',
        fontSize: ['15px', null, null, 2, '15px', 4],
        lineHeight: [1.86, null, null, null, 1.86, 1.5],
        m: [null, null, null, '20px auto 0', '15px 0 0', null, 'unset'],
    },
    list: {
        gap: '0 18px',
        fontFamily: 'headingAlt',
        gridTemplateColumns: ['repeat(2, 142px)', null, null, 'repeat(2, 200px)'],
        justifyContent: [null, null, null, 'center', 'unset'],
        listStyle: 'none',
        display: 'block',
        mt: [4, null, null, 5, 4, 5],
        p: 0,
        li: {
            fontSize: [0, 1, null, 2, '15px', 2],
            fontWeight: 600,
            alignItems: 'center',
            color: 'textSecondary',
            fontFamily: 'headingAlt',
            display: 'flex',
            lineHeight: [2.81, null, null, null, 2.2, 2.81],
        },
    },
};
